/** @format */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* scrollbar-width: none */
}
body::-webkit-scrollbar {
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  width: 1px;
  z-index: 999;
}
html {
  --scrollbarBG: #ee8181;
  --thumbBG: #d6bcbc;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
