input[type="radio"] {
  -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
  -moz-appearance: checkbox;    /* Firefox */
  -ms-appearance: checkbox;     /* not currently supported */
}


.radioButton span.MuiFormControlLabel-label {
  font-weight: 600;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.textarea-allergy-notes {
  resize: none;
  border-color: #c5c5c5;
  border-radius: 5px;
}